import lodash from 'lodash';
import HereMaps from './HereMaps.js';

/**
 * Loads static HERE maps on request.
 *
 * @requires lodash
 * @extends module:project/Maps.HereMaps
 * @memberof module:project/Maps
 * @version 1.0.0
 * @author Rocco Janse <rocco.janse@valtech.nl>
 */
class HereMapsStatic extends HereMaps {
    /**
     * Upgrades DOM element and sets config.
     * @param {jQueryElement} $element DOM Element to be upgraded.
     * @param {object} options Configuration options.
     */
    constructor($element, options) {
        // defaults
        let config = {
            staticApiUrl: 'https://image.maps.hereapi.com/mia/v3/base/mc/',
            zoom: 12,
            width: 310,
            height: 175,
        };
        config = $.extend(config, options);

        super($element, config);
    }

    /**
     * Builds image url, loads image and handles resize events.
     */
    init() {
        this.imageLoaded = false;

        // get attributes
        const attrs = this.getElementAttributes(this.$element[0], 'data-hms');
        if (typeof attrs['data-hms-width'] !== 'undefined') {
            this.config.width = attrs['data-hms-width'];
        }
        if (typeof attrs['data-hms-height'] !== 'undefined') {
            this.config.height = attrs['data-hms-height'];
        }
        if (typeof attrs['data-hms-latlng'] !== 'undefined') {
            this.config.latlng = attrs['data-hms-latlng'];
        }
        if (typeof attrs['data-hms-zoom'] !== 'undefined') {
            this.config.zoom = attrs['data-hms-zoom'];
        }

        // build url
        this.imageUrl = `${this.config.staticApiUrl}center:${this.config.latlng};zoom=${this.config.zoom}/${this.config.width}x${this.config.height}/png?&apiKey=${this.config.apiKey}`;

        // events
        $(window).on(
            'resize',
            lodash.debounce(() => {
                this.loadImage();
            }, 200)
        );

        // load!
        this.loadImage();
    }

    /**
     * Loads static image when applicable.
     */
    loadImage() {
        if (viewport.is('>=lg') && !this.imageLoaded) {
            this.$element.attr('src', this.imageUrl);
            this.imageLoaded = true;
        }
    }
}

// register to Component Handler
window.ComponentHandler.register({
    constructor: HereMapsStatic,
    classAsString: 'HereMapsStatic',
    cssClass: 'js-here-maps-static',
});

export default HereMapsStatic;
